export default {
  productos : [
    {
      image: 'viveres.jpg',
      title: 'Víveres en general',
    },
    {
      image: 'meat.jpg',
      title: 'Productos frigoríficos',
    },
    {
      image: 'charcuteria.jpg',
      title: 'Productos de charcutería',
    }
  ]
}