import React from 'react';

export default function ImageCard({image, title, ...props}) {

  return (
    <div className="w-col w-col-4">
    <div className="white-box transparent">
      <img
        src={`images/${image}`}
        alt={image}
        className="fullwidth-image"
        style={{objectFit: 'cover'}}
      />
      <h3 className="product-card-title">{title}</h3>
    </div>
  </div>
  )
}