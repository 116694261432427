import React, { useState, useEffect } from 'react';
import { scrollto } from './utils/scrollto';
import ImageCard from './components/image-card';
import { useFormInput, useErrors, useSubmitReady } from './hooks/useForm';
import axios from 'axios';
import './eibar-test.webflow.css';
import './normalize.css';
import './webflow.css';
import data from './data';

const { productos } = data;

function App() {
  // State
  const [buttonText, setbuttonText] = useState('Enviar');
  // Inputs
  const name = useFormInput('');
  const email = useFormInput('');
  const msg = useFormInput('');

  // Error handlers
  const emailErrors = useErrors({ email: email.state });
  const nameErrors = useErrors({ name: name.state });
  const msgErrors = useErrors({ msg: msg.state });

  // Sumbit handlers
  const submitReady = useSubmitReady({
    name: name.state,
    email: email.state,
  });

  const handleSubmit = async e => {
    setbuttonText('Enviando...');
    e.preventDefault();
    const payload = {
      name: name.state.value,
      email: email.state.value,
      msg: msg.state.value,
      page: window.location.pathname,
      date: new Date().toDateString()
    };
    console.log(payload, 'payload');
    await axios('https://distribuidoranb-form-handler.herokuapp.com/', {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      data: payload
    })
      .then(x => {
        setbuttonText('Enviado!');
        setTimeout(()=>{
          name.setters.setValue('');
          email.setters.setValue('');
          msg.setters.setValue('');
          setbuttonText('Enviar');
        },8000);
        console.log(x);
      })
      .catch(err =>{
        name.setters.setValue('');
        email.setters.setValue('');
        msg.setters.setValue('');
        setbuttonText('Error, intente de nuevo.');
        setTimeout(()=>{
          setbuttonText('Enviar');
        },4000);
        console.log(err);
      });
  };
  return (
    <main>
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        className="navigation-bar w-nav"
      >
        <div className="w-container nav-wrapper">
          <a href="index.html" className="brand-link w-nav-brand w--current">
            <img src="/images/simple-logo.png" className="logoimg" />
          </a>
          <nav role="navigation" className="navigation-menu w-nav-menu" />
        </div>
      </div>
      <div className="hero-section centered">
        <div data-ix="new-interaction" className="w-container">
          <h1 data-ix="fade-in-bottom-page-loads" className="hero-heading">
            Nunes & Bagnati
          </h1>
          <div data-ix="fade-in-bottom-page-loads" className="hero-subheading">
            Distribuidora de alimentos
          </div>
          <div data-ix="fade-in-bottom-page-loads">
            <button
              onClick={() => scrollto('form')}
              href="#"
              className="button"
            >
              Contáctanos
            </button>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="w-container">
          <div className="section-title-group">
            <h2 className="section-heading centered">¿Qué hacemos?</h2>
            <div className="section-subheading center custom-subheading">
              <p className="left">
                Somos una empresa constituida en el año 2013 con la amplia
                visión de dedicarse a la{' '}
                <span className="italic">
                  comercialización y distribución de productos de la industria
                  alimentaria en Venezuela
                </span>
                .
              </p>

              <p className="left">
                En el 2016 la empresa decide incursionar en nuevos proyectos
                para poder adaptarse a las exigencias del mercado. Tenemos como
                finalidad suministrar productos de alta calidad que cumplan con
                las expectativas de nuestros clientes, a precios competitivos,
                con un servicio personalizado de óptima calidad, rápido y
                respaldados por un excelente equipo de trabajo.
              </p>
            </div>
          </div>
          <div className="w-row">
            <div className="w-col w-col-4">
              <div className="white-box">
                <div className="grid-image">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="plane-arrival"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    className="card-icon"
                  >
                    <path
                      fill="currentColor"
                      d="M624 448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h608c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM44.81 205.66l88.74 80a62.607 62.607 0 0 0 25.47 13.93l287.6 78.35c26.48 7.21 54.56 8.72 81 1.36 29.67-8.27 43.44-21.21 47.25-35.71 3.83-14.5-1.73-32.71-23.37-54.96-19.28-19.82-44.35-32.79-70.83-40l-97.51-26.56L282.8 30.22c-1.51-5.81-5.95-10.35-11.66-11.91L206.05.58c-10.56-2.88-20.9 5.32-20.71 16.44l47.92 164.21-102.2-27.84-27.59-67.88c-1.93-4.89-6.01-8.57-11.02-9.93L52.72 64.75c-10.34-2.82-20.53 5-20.72 15.88l.23 101.78c.19 8.91 6.03 17.34 12.58 23.25z"
                      class=""
                    />
                  </svg>
                </div>

                <h3 className="card-title">Importamos</h3>
                <p>
                  Contamos con un sólido portafolio de productos importados en
                  Alimentos.
                </p>
              </div>
            </div>
            <div className="w-col w-col-4">
              <div className="white-box">
                <div className="grid-image">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="store"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 616 512"
                    className="card-icon"
                  >
                    <path
                      fill="currentColor"
                      d="M602 118.6L537.1 15C531.3 5.7 521 0 510 0H106C95 0 84.7 5.7 78.9 15L14 118.6c-33.5 53.5-3.8 127.9 58.8 136.4 4.5.6 9.1.9 13.7.9 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18 20.1 44.3 33.1 73.8 33.1 29.6 0 55.8-13 73.8-33.1 18.1 20.1 44.3 33.1 73.8 33.1 4.7 0 9.2-.3 13.7-.9 62.8-8.4 92.6-82.8 59-136.4zM529.5 288c-10 0-19.9-1.5-29.5-3.8V384H116v-99.8c-9.6 2.2-19.5 3.8-29.5 3.8-6 0-12.1-.4-18-1.2-5.6-.8-11.1-2.1-16.4-3.6V480c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32V283.2c-5.4 1.6-10.8 2.9-16.4 3.6-6.1.8-12.1 1.2-18.2 1.2z"
                      class=""
                    />
                  </svg>
                </div>
                <h3 className="card-title">Comercializamos</h3>
                <p>
                  Proporcionamos un servicio personalizado donde el cliente
                  puede obtener la mayor información sobre nuestra gama de
                  productos.
                </p>
              </div>
            </div>
            <div className="w-col w-col-4">
              <div className="white-box">
                <div className="grid-image">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="chart-network"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    className="card-icon"
                  >
                    <path
                      fill="currentColor"
                      d="M568 368c-19.1 0-36.3 7.6-49.2 19.7L440.6 343c4.5-12.2 7.4-25.2 7.4-39 0-61.9-50.1-112-112-112-8.4 0-16.6 1.1-24.4 2.9l-32.2-69c15-13.2 24.6-32.3 24.6-53.8 0-39.8-32.2-72-72-72s-72 32.2-72 72 32.2 72 72 72c.9 0 1.8-.2 2.7-.3l33.5 71.7C241.5 235.9 224 267.8 224 304c0 61.9 50.1 112 112 112 30.7 0 58.6-12.4 78.8-32.5l82.2 47c-.4 3.1-1 6.3-1 9.5 0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72zM232 96c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24zm104 272c-35.3 0-64-28.7-64-64s28.7-64 64-64 64 28.7 64 64-28.7 64-64 64zm232 96c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24zm-54.4-261.2l-19.2-25.6-48 36 19.2 25.6 48-36zM576 192c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zM152 320h48v-32h-48v32zm-88-80c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64z"
                      class=""
                    />
                  </svg>
                </div>
                <h3 className="card-title">Distribuimos</h3>
                <p>
                  Ponemos a tu disposición un servicio de distribución que llega
                  a todo el territorio nacional.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section accent">
        <div className="w-container">
          <div className="section-title-group">
            <h2 className="section-heading centered">Nuestros Productos</h2>
            <div className="section-subheading center custom-subheading">
              <p className="left">
                Importamos y distribuimos{' '}
                <span className="italic">
                  productos alimenticios principalmente procesados
                </span>
                , pero además proveemos suministros, consumibles y equipos de
                oficina. Entre los productos que brindamos se encuentran:
              </p>
            </div>
          </div>
          <div className="w-row">
            {productos.map(el => (
              <ImageCard {...el} />
            ))}
          </div>
        </div>
      </div>
      <div class="section" id="form">
        <div class="w-container">
          <div class="section-title-group">
            <h2 class="section-heading centered">Contáctanos</h2>
          </div>
          <p className="custom-subheading left">
            Llámanos al <a href="tel:+582122614254">(212) 261 4254</a> <br />
            <br />
            Escríbenos a{' '}
            <a href="tel:+582122614254">administracion@distribuidoranb.com</a>
            <br />
            <br />o llena y envia el siguiente formulario; te contactaremos a la
            brevedad posible:
          </p>
          <div class="form-wrapper w-form">
            {/* <form name="email-form" data-name="Email Form" class="form" action="https://formspree.io/poiuy@yopmail.com" method="POST" > */}
            <form
              name="email-form"
              className="form"
              onSubmit={e => handleSubmit(e)}
            >
              <input
                placeholder="Nombre *"
                value={name.state.value}
                {...name.events}
                className={`form-field w-input`}
                type="text"
                maxlength="256"
                name="Name"
                id="Name"
                required=""
              />
              <input
                value={email.state.value}
                {...email.events}
                className={`form-field w-input`}
                type="email"
                maxlength="256"
                name="Email"
                placeholder="Email *"
                id="Email"
                required
              />
              <textarea
                name="Message"
                placeholder="Mensaje"
                maxlength="5000"
                id="msg"
                value={msg.state.value}
                {...msg.events}
                className={`form-field text-area w-input`}
              />
              <button
                type="submit"
                disabled={!submitReady}
                value="Send Carrier Pigeon"
                class="button full-width w-button"
              >
                {buttonText}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="footer-banner">
        <h6>Distribuidora de Alimentos Nunes & Bagnati C.A.</h6>
        <span>Desarrollado por AshokaLab</span>
      </div>
    </main>
  );
}

export default App;
