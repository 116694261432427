const validate = (values) => {
  const errors = {};


  if (values.email !== undefined && values.email.touched) {
    if (!values.email.value) {
      errors.email = 'Your email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email.value)) {
      errors.email = 'Invalid email address';
    }
  }

  if (values.name !== undefined && values.name.touched) {
    if (!values.name.value) {
      errors.name = 'Your name is required';
    }
  }

  // if (values.phone?.touched) {
  //   if (!values.phone.value) {
  //     errors.phone = 'Phone number required';
  //   } else if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(String(values.phone.value))) {
  //     errors.phone = 'Invalid phone number';
  //   }
  // }

  return errors;
};

export default validate;
